import React, { useState, useEffect, useContext } from "react";
import "../PatientForm/FormStatus.scss";
import "./RegistrationForms.scss";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import axios from "axios";
import Modal from "../../Components/Modal/Modal";
import Link from "@material-ui/icons/Link";
import Button from "@material-ui/core/Button";
import { FormControl, InputLabel } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { Severity } from "../../ContextLib/CoreConsumer/Components/SnackbarMessage";
import PageviewIcon from "@material-ui/icons/PageviewOutlined";
import LinkIcon from "@material-ui/icons/Link";
import { transparent } from "material-ui/styles/colors";

import { MdLowPriority } from "react-icons/md";
import { useTour } from "@reactour/tour";
import TourSteps from "../../Store/TourSteps";
import { TourServiceContext } from "../../Store/TourServiceProvider";
import {
  useFiltersContext,
  useRequest,
  useUIContext,
} from "../../ContextLib/contextHooks";

import JWT from "expo-jwt";
import Constants from "../../constants";
import Session from "../../session";
import Storage from "../../storage";
import "./PatientForms.scss"
const PatientForm = ({ coverKey }) => {
  let componentId = "patient-form";
  const ui = useUIContext(componentId);
  const filtersContext = useFiltersContext(componentId);

  const { setCurrentTab, setTourNext } = useContext(TourServiceContext);

  const {
    isOpen,
    currentStep,
    setIsOpen,
    setCurrentStep,
    setSteps,
    setDisabledActions,
    stepsLength,
    disabledActions,
  } = useTour();

  const [patientFormsData, setPatientFormsData] = useState([]);
  const [modalLocKey, setModalLocKey] = useState(0);
  const [hasLocData, setHasLocData] = useState(false);
  const [showGetLinkModal, setShowGetLinkModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [staticLink, setStaticLink] = useState("");
  const [urlLink, setUrlLink] = useState("");
  const [locData, setLocData] = useState([]);
  const [modalLocName, setModalLocName] = useState("");

  const loadData = async () => {
    ui.ShowOverlay();

    let key = `${process.env.REACT_APP_FORMS_JWTKEY_32BIT}`;
    let payload = {
      coverKey: coverKey,
    };
    let tokenold = JWT.encode(payload, key, { algorithm: "HS256" });
   
    let token=Storage.getItem(Constants.currUserKey);
    // console.log(token.jwt);
    Session.setItem(Constants.jwtKey, token);
    const config = {
      headers: {
        Authorization: `Bearer ${token.jwt}`,
        "x-frm-adm": "bb2910fb-1a14-4df9-9483-e25e40387163",
      },
    };
    const _axios = axios.create();
    const url = `${process.env.REACT_APP_PATIENT_FORMS}/getForms?coverKey=${coverKey}`;
    const res = await _axios.get(url, config);

    let formsData = [];
    if (res?.data) {
      formsData = res.data.map((item, idx) => {
        return {
          id: item.Id,
          name: item.Name,
          pathName: item.PathName,
        };
      });
      setPatientFormsData(formsData);
    }
    getLocData();
    ui.HideOverlay();
  };

  const getLocData = async () => {
    try {
      const urlLocData = `${process.env.REACT_APP_J45_API}/practices/${coverKey}/Locations`;
      const resLocData = await axios.get(urlLocData);

      if (resLocData?.data.length > 0) {
        setTimeout(() => {
          let tempLocData = [];
          resLocData.data.map((item, idx) => {
            var locData = {
              id: idx,
              locKey: item.locKey,
              orgName: item.orgName,
            };
            if (item.status > 0) tempLocData.push(locData);
          });
          if (tempLocData.length > 0) {
            setHasLocData(true);
            setLocData(tempLocData);
            setModalLocKey(tempLocData[0].locKey);
            setModalLocName(tempLocData[0].orgName);
          }
        }, 200);
      }
    } catch (ex) {
      ui.ShowSnackbar(
        `Unexpected error while getting Location data. ${ex}`,
        Severity.error
      );
    }
  };

  const renderModalLocName = () => {
    return (
      <>
        {hasLocData ? (
          locData.map((data) => {
            return <option value={data.orgName}>{data.orgName}</option>;
          })
        ) : (
          <option value={0}>No Location Data</option>
        )}
      </>
    );
  };

  const renderModalLocKey = () => {
    return (
      <>
        {hasLocData ? (
          locData.map((data) => {
            return <option value={data.locKey}>{data.locKey}</option>;
          })
        ) : (
          <option value={0}>-</option>
        )}
      </>
    );
  };

  const copyLinkClick = () => {
    navigator.clipboard.writeText(urlLink);
    ui.ShowSnackbar("Copy to Clipboard");
  };

  const handleOnLocKeyChange = (e) => {
    setModalLocKey(e.target.value);
    var tempLoc = locData.filter((loc) => {
      if (loc.locKey == e.target.value) return loc;
    });
    setModalLocName(tempLoc[0].orgName);
    var url = `${process.env.REACT_APP_FORMS_URL}?pn=${staticLink}&coverKey=${coverKey}&locKey=${e.target.value}`;
    setUrlLink(url);
  };

  const handleOnLocNameChange = (e) => {
    setModalLocName(e.target.value);
    var tempLoc = locData.filter((loc) => {
      if (loc.orgName === e.target.value) return loc;
    });
    setModalLocKey(tempLoc[0].locKey);
    var url = `${process.env.REACT_APP_FORMS_URL}?pn=${staticLink}&coverKey=${coverKey}&locKey=${tempLoc[0].locKey}`;
    setUrlLink(url);
  };

  const getLinkClick = (link) => {
    var url = `${process.env.REACT_APP_FORMS_URL}?pn=${link}&coverKey=${coverKey}&locKey=${modalLocKey}`;
    if (!hasLocData)
      url = `${process.env.REACT_APP_FORMS_URL}?pn=${link}&coverKey=${coverKey}`;
    setStaticLink(link);
    setUrlLink(url);
    getModalTitle(link);
  };

  const getModalTitle = (link) => {
    setTimeout(() => {
      let tmpPatientFormsData = patientFormsData.filter((item) => {
        if (item.pathName === link) return item;
      });
      setModalTitle(tmpPatientFormsData[0].name);
      setShowGetLinkModal(true);
    }, 250);
  };

  const handleOnModalDoneClick = () => {
    setShowGetLinkModal(false);
    if (locData.length > 0) {
      setModalLocKey(locData[0].locKey);
      setModalLocName(locData[0].orgName);
    }
  };

  const patientcolumns = [
    { name: "name", header: "Form Name", defaultFlex: 1 },
    {
      name: "pathName",
      header: "Actions",
      defaultWidth: 120,
      type: "string",
      className: "actions-column",
      render: ({ value }) => (
        <>
          <a
            href="#"
            title="View Form"
            data-tut="reactour__magnifyingglass"
            onClick={() =>
              window.open(
                `${process.env.REACT_APP_FORMS_URL}?pn=${value}&coverKey=${coverKey}&viewMode=1`,
                "_blank",
                ""
              )
            }
          >
            <PageviewIcon />
          </a>
          <a
            href="#"
            title="Get Link"
            data-tut="reactour__link"
            onClick={() => getLinkClick(value)}
          >
            <LinkIcon />
          </a>
        </>
      ),
    },
  ];

  const gridStyle = {
    height: "calc(100vh - 340px)",
    fontSize: "13px",
  };

  useEffect(() => {
    if (coverKey > 0) loadData();
  }, [coverKey]);

  useEffect(() => {
    // Set Tours
    setIsOpen(false);
    setSteps(TourSteps.tourStepsFormTemplates);
    setCurrentTab(componentId);
  }, []);
  return (
    <>
      <div className="patient-forms">
        <div className="view-header"></div>
        <div className="view-body">
          <ReactDataGrid
            idProperty="id"
            columns={patientcolumns}
            pagination={true}
            dataSource={patientFormsData}
            style={gridStyle}
          />
        </div>
      </div>

      <Modal show={showGetLinkModal} hideCloseButton={true}>
        <div className="modal__content--title">
          <Link />
          <h2>{modalTitle}</h2>
        </div>

        <div className="modal__content--body">
          <div style={{ width: "200px" }}>
            <InputLabel id="locKey" className="modal--label">
              LocKey
            </InputLabel>
            <FormControl condense fullWidth variant="outlined" size="small">
              <select
                id="locKey"
                value={modalLocKey}
                onChange={handleOnLocKeyChange}
              >
                {renderModalLocKey()}
              </select>
            </FormControl>
          </div>
          <div style={{ width: "410px", marginLeft: "20px" }}>
            <InputLabel id="urlLink" className="modal--label">
              Location Name
            </InputLabel>
            <FormControl condense fullWidth variant="outlined" size="small">
              <select
                id="locName"
                value={modalLocName}
                onChange={handleOnLocNameChange}
              >
                {renderModalLocName()}
              </select>
            </FormControl>
          </div>
        </div>

        <div className="modal__content--body">
          <div style={{ width: "610px" }}>
            <InputLabel id="urlLink" className="modal--label">
              Form URL Link
            </InputLabel>
            <TextField
              size="small"
              fullWidth
              disabled
              id="urlLink"
              variant="outlined"
              value={urlLink}
            />
          </div>
          <p onClick={copyLinkClick}>Copy Link</p>
        </div>

        <div className="modal__content--button">
          <Button
            onClick={handleOnModalDoneClick}
            variant="contained"
            color="primary"
            type="button"
          >
            Done
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default PatientForm;
